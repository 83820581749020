<template>
  <v-card class="card-shadow border-radius-xl" @click="_detail(lesson)">
    <div class="px-4 py-4">
      <div class="d-flex">
        <!-- <v-avatar size="74" class="border-radius-md bg-gradient-default pa-2">
          <v-img :src="card.image" width="50" height="50"> </v-img>
        </v-avatar> -->
        <div class="my-auto">
          <h6 class="text-h6 text-typo font-weight-bold mb-2">
            {{ lesson.name }}
          </h6>
          <span v-if="lesson.teacherFirstName">{{
            lesson.teacherFirstName
          }}</span>
          <span v-if="lesson.teacherLastName"
            >, {{ lesson.teacherLastName }}</span
          >
          <!-- <p class="text-wrap" v-if="userData.role == 'superadmin'">
            {{ lesson.ref.path }}
          </p> -->
          <!-- <span class="avatar-group d-flex">
            <v-avatar
              size="24"
              class="border border-white"
              v-for="avatar in card.avatars"
              :key="avatar.image"
            >
              <img :src="avatar.image" alt="Avatar" />
            </v-avatar>
          </span> -->
        </div>
        <v-menu
          transition="slide-y-transition"
          offset-y
          offset-x
          min-width="150"
        >
          <template v-slot:activator="{ on, attrs }">
            <v-btn
              icon
              :ripple="false"
              class="text-secondary ms-auto mt-3"
              v-bind="attrs"
              v-on="on"
              small
            >
              <v-icon size="16">fas fa-ellipsis-v</v-icon>
            </v-btn>
          </template>

          <v-list class="py-0">
            <!-- <v-list-item
              class="list-item-hover-active"
            >
              <v-list-item-content class="pa-0">
                <v-list-item-title> Засаx </v-list-item-title>
              </v-list-item-content>
            </v-list-item> -->
          </v-list>
        </v-menu>
      </div>
      <p class="text-sm mt-4 text-body">{{ lesson.description }}</p>
    </div>
  </v-card>
</template>
<script>
export default {
  data: function () {
    return {};
  },
  props: {
    lesson: {
      type: Object,
      required: true,
    },
  },

  methods: {
    _detail(item) {
      console.log(item.ref.path);
      this.$router.push({
        name: "StudentLessonDetail",
        params: {
          path: item.ref.path,
        },
      });
    },
  },
};
</script>
