<template>
  <v-container class="my-6 mx-auto" style="max-width: 1500px">
    <v-row>
      <v-col cols="12">
        <h5 class="text-h5 text-typo font-weight-bold mb-2">Xичээлүүд</h5>
        <p class="text-body">Сурагчийн үзэx xичээлүүд</p>
      </v-col>
    </v-row>
    <section v-if="lessons && lessons.length > 0">
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="lesson in lessons"
          :key="lesson.id"
        >
          <StudentsLessonCard
            :lesson="lesson"
            @click="_detail(lesson)"
            v-bind="$attrs"
          ></StudentsLessonCard>
        </v-col>
      </v-row>
    </section>

    <section v-if="selectedLessons2 && selectedLessons2.length > 0">
      <h4 class="my-10 blue--text">Сонгон суралцаx xичээлүүд</h4>
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="lesson in selectedLessons2"
          :key="lesson.id"
        >
          <StudentsLessonCard
            :lesson="lesson"
            @click="_detail(lesson)"
            v-bind="$attrs"
          ></StudentsLessonCard>
        </v-col>
      </v-row>
    </section>

    <!-- <section v-if="selectedLessons && selectedLessons.length > 0">
      <h4 class="my-10 blue--text">Сонгон суралцаx xичээлүүд</h4>
      <v-row>
        <v-col
          lg="4"
          md="6"
          cols="12"
          v-for="lesson in selectedLessons"
          :key="lesson.id"
        >
          <StudentsLessonCard
            :lesson="lesson"
            @click="_detail(lesson)"
            v-bind="$attrs"
          ></StudentsLessonCard>
        </v-col>
      </v-row>
    </section> -->
  </v-container>
</template>

<script>
const fb = require("@/firebaseConfig.js");
import { mapState } from "vuex";
import { sync } from "vuex-pathify";
import StudentsLessonCard from "./StudentsLessonCard.vue";
export default {
  components: { StudentsLessonCard },
  data: () => ({
    lessons: null,
    selectedLessons: null,
    selectedLessons2: null,
    lessonCategories: null,
  }),
  computed: {
    ...mapState(["userProfile", "currentUser"]),
    ...sync("*"),
  },
  created() {
    console.log(
      this.userData["classGroup-" + this.userData.school.currentYear]
    );
    // console.log(this.userData.ref.parent.parent)
    this._setupp();
  },
  methods: {
    // async _setupp() {
    //   this.userData.ref.collection("lessons-interested-"+this.userData.school.currentYear)
    //     .onSnapshot((docs) => {
    //       this.lessons = [];
    //       docs.forEach((doc) => {
    //         let lesson = doc.data();
    //         lesson.id = doc.id;
    //         lesson.ref = doc.ref;
    //         this.lessons.push(lesson);
    //       });
    //     });
    // },
    async _setupp() {
      var query;
      if (fb.EYESH_APP) {
        query = fb.db.collection("categories");
      } else {
        query = fb.dbeyesh800.collection("categories");
      }
      var xx = await query
        .where("lessonType", "==", 3)
        .orderBy("name", "asc")
        .get()
      if(!xx.empty){
        this.lessonCategories = []
        xx.docs.forEach(cat=>{
          let cc = cat.data()
          cc.id = cat.id
          cc.ref = cat.ref
          this.lessonCategories.push(cc);
        })
      }
        // xx
        // .then((querySnapshot) => {
        //   this.lessonCategories = [];
        //   querySnapshot.forEach((doc) => {
        //     let lesson = doc.data();
        //     lesson.id = doc.id;
        //     lesson.ref = doc.ref;
        //     lesson.name2 = lesson.name + " - " + lesson.lessonTypeName;
        //     this.lessonCategories.push(lesson);
        //   });
        // });

      var studentClassGroupRef =
        this.userData["classGroup-" + this.userData.school.currentYear];

      console.log(studentClassGroupRef);
      if (studentClassGroupRef) {
        this.userData.school.ref
          .collection("lessons")
          .where("classGroupRefs", "array-contains", studentClassGroupRef)
          .onSnapshot((docs) => {
            this.lessons = [];
            docs.forEach((doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              this.lessons.push(lesson);
            });
          });
      }

      // this.userData.ref
      //   .collection("selected-lessons-" + this.userData.school.currentYear)
      //   .onSnapshot(async (docs) => {
      //     this.selectedLessons = [];
      //     docs.forEach(async (doc) => {
      //       // let lesson = doc.data();
      //       // lesson.id = doc.id;
      //       // lesson.ref = doc.ref;
      //       // console.log(lesson.id)

      //       var ll = await this.userData.ref.parent.parent.collection("lessons").doc(doc.id).get()
      //       let lesson = ll.data()
      //       lesson.id = ll.id
      //       lesson.ref = ll.ref
      //       this.selectedLessons.push(lesson);
      //     });
      //   });

      if (this.userData.ref) {
        this.userData.ref
          .collection("lessons-interested-" + this.userData.school.currentYear)
          .onSnapshot(async (docs) => {
            this.selectedLessons = [];
            docs.forEach(async (doc) => {
              console.log(doc.data().lessonCategoryRef.path);
              if (fb.EYESH_APP == false) {
                fb.dbeyesh800
                  .doc(doc.data().lessonCategoryRef)
                  .get()
                  .then((doc) => {
                    this.userData.ref.parent.parent
                      .collection("lessons")
                      .where("deleted", "==", false)
                      .where("categoryName", "==", doc.data().name)
                      .onSnapshot((docs) => {
                        docs.forEach((doc) => {
                          let lesson = doc.data();
                          lesson.id = doc.id;
                          lesson.ref = doc.ref;
                          this.selectedLessons.push(lesson);
                        });
                      });
                  });
              } else {
                this.userData.ref.parent.parent
                  .collection("lessons")
                  .where("deleted", "==", false)
                  .where("categoryRef", "==", doc.data().lessonCategoryRef)
                  .onSnapshot((docs) => {
                    docs.forEach((doc) => {
                      let lesson = doc.data();
                      lesson.id = doc.id;
                      lesson.ref = doc.ref;
                      this.selectedLessons.push(lesson);
                    });
                  });
              }
            });
          });
          console.log(this.lessonCategories)
        this.userData.ref
          .collection("selected-lessons-" + this.userData.school.currentYear)
          .onSnapshot(async (docs) => {
            this.selectedLessons2 = [];
            docs.forEach(async (doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              console.log(lesson, lesson.yeshLessonCode);
      
              var found = this._isIn(lesson)
              console.log(found)
              if(found){
                console.log("FOUND") //TOTTS
              }
              this.selectedLessons2.push(lesson);
            });
          });
      }
    },
    _isIn(lesson) {
      if (this.lessonCategories)
        for (const cat of this.lessonCategories) {
          console.log(cat.yeshLessonCode, lesson.yeshLessonCode)
          if (cat.yeshLessonCode == lesson.yeshLessonCode) {
             return true;
          }
        }
      return false;
    },
  },
};
</script>
